import React from 'react'
import Home from "./Pages/Home.js"
function App() {
  return (
	 <div>
		<Home />
	 </div>
  )
}

export default App